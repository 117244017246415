

























































































































































































































































































































































































































































































































































































































































































































































































































































@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}

.v-image {
  position: relative;
}

.btn-select {
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 5px;
}

.btn-ver {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  right: 5px;
}

.select-status::v-deep {
  .v-input__slot {
    width: 150px;
    margin-bottom: 0 !important;
  }
}
